.realtime_route:hover,
.reporting_route:hover {
  background-color: "rgba(59,130,246,.5)" !important;
}

@media (min-width: 640px) {
  .hide {
    display: none;
  }
}

.container{
  position: "fixed";
  width: "90px";
  height: "calc(100vh - 80px)";
  background-color: "#024D87";
  box-shadow:
    "0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3)";
  display: "flex";
  flex: "column";
  justify-content: space-between;
  align-items: center ;
  margin-top: "65px";
  margin-left: "20px";
  margin-bottom: "20px" ;
  border-radius: "24px";
  padding-bottom: "30px";
}